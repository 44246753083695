import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Chess } from 'chess.js'
import axiosCSRF from '../util/axiosCSRF';
import { createFen } from '../actions/fen';
import AnalysisHeader from './AnalysisHeader';
import AnalysisBoard from './AnalysisBoard';
import AnalysisControls from './AnalysisControls';
import AnalysisCastlingCheckbox from './AnalysisCastlingCheckbox';
import AnalysisSettings from './AnalysisSettings';
import GoogleAdResponsive from '../adsense/GoogleAdResponsive';
import isEmpty from '../util/utils';

class Analysis extends React.Component {
  constructor(props) {
    super(props);
    this.handleChessgroundMoveNav = this.handleChessgroundMoveNav.bind(this);
    this.handleCalculate = this.handleCalculate.bind(this);
    this.cgEventChange = this.cgEventChange.bind(this);
    this.createPosition = this.createPosition.bind(this);
    this.handleMoveAutomatically = this.handleMoveAutomatically.bind(this);
    this.updateMoves = this.updateMoves.bind(this);
    this.updateOrientation = this.updateOrientation.bind(this);
    this.updateCastling = this.updateCastling.bind(this);
    this.setCastling = this.setCastling.bind(this);
    this.resetBoard = this.resetBoard.bind(this);
    this.clearBoard = this.clearBoard.bind(this);
    this.sequenceMove = this.sequenceMove.bind(this);
    this.drawArrows = this.drawArrows.bind(this);
    this.state = {
      color: null,
      bestMove: null,
      score: null,
      moveNum: 0,
      moveCount: 0,
      castling: 'KQkq',
      sequence: [],
      sequenceStart: null,
    };
  }

  // supports edit and new
  componentDidMount() {
    if (!window.location.pathname.includes('edit')) {
      window.history.pushState('new', 'Analysis', '/analysis/new');
    }

    const url = '/api/v1' + window.location.pathname;
    axiosCSRF
      .get(url)
      .then((response) => {
        const { position } = response.data;
        const moveNum = position.moves.length - 1;
        const moveCount = moveNum;
        const orientation = position.orientation === 'w' ? 'white' : 'black';
        cg.set({ fen: position.moves[moveNum], orientation });
        this.setCastling('KQkq');
        this.setState({
          position, orientation, moveNum, moveCount,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  setCastling(castlingString) {
    let castling = '';
    castlingString.split('').forEach((val) => {
      if (AnalysisCastlingCheckbox.castlingAvailable(val)) castling += val;
    });

    castling = castling || '-';
    this.setState({ castling });
  }

  updateCastling(value, checked) {
    let { castling } = this.state;
    if (!checked) {
      castling = castling.replace(value, '');
    } else {
      castling = castling.replace('-', '');
      if (AnalysisCastlingCheckbox.castlingAvailable(value)) {
        castling += value;
        castling.split('').filter((v, i, a) => a.indexOf(v) === i).sort();
      }
    }
    castling = castling || '-';
    this.setState({ castling });
  }

  handleChessgroundMoveNav(btnVal) {
    const { position } = this.state;
    let { moveNum } = this.state;

    switch (btnVal) {
      case 'first':
        moveNum = 0;
        break;
      case 'prev':
        moveNum -= 1;
        break;
      case 'next':
        moveNum += 1;
        break;
      case 'last':
        moveNum = position.moves.length - 1;
        break;
      default:
        moveNum = 0;
    }

    this.setState({ moveNum });
    cg.set({ fen: position.moves[moveNum] });
  }

  cgEventChange(_origin, _dest, _metadata) {
    const { castling } = this.state;
    this.setCastling(castling);
    this.setState({
      bestMove: null,
      score: null,
      sequence: [],
      sequenceStart: null,
    });

    if (window.location.pathname.includes('edit')) {
      this.updateMoves([cg.getFen()]);
    } else {
      this.createPosition([cg.getFen()]);
    }
  }

  createPosition(fens) {
    axiosCSRF
      .post('/api/v1/analysis.json', {
        fens,
      })
      .then((response) => {
        const { position } = response.data;
        const moveNum = position.moves.length - 1;
        const moveCount = moveNum;
        this.setState({ position, moveNum, moveCount });
        window.history.pushState(
          'edit',
          'Analysis',
          '/analysis/' + response.data.position.id + '/edit',
        );
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  updateMoves(fens) {
    const { position } = this.state;

    const { moveNum, sequenceStart } = this.state;
    const truncateAt = (sequenceStart === null ? moveNum : sequenceStart) + 1;

    position.moves = position.moves.slice(0, truncateAt).concat(fens);
    const moveCount = position.moves.length - 1;
    this.setState({ position, moveCount, moveNum: moveCount });

    axiosCSRF
      .put(
        '/api/v1/analysis/' + position.id,
        { position: { moves: position.moves } },
      )
      .catch((error) => {
        this.setState({ error: error.response.data.errors });
      });
  }

  drawArrows() {
    const { sequence } = this.state;
    const shapeSet = [];

    const shapeColors = ['green', 'blue', 'paleGreen', 'paleBlue'];

    let orig;
    let dest;

    const maxLength = Math.min(...[sequence.length, shapeColors.length]);
    for (let i = 0; i < maxLength; i++) {
      [orig, dest] = sequence[i].match(/.{1,2}/g);  
      shapeSet.push({ orig, dest, brush: shapeColors.shift() });
    }

    cg.setShapes(shapeSet);
  }

  sequenceMove(sequenceIndex) {
    const {
      color,
      castling,
      sequence,
      sequenceStart,
      position,
      moveNum,
    } = this.state;

    if (sequence.length === 0) return; // checkmate/stalemate

    const { chess } = this.props;

    let currFen;

    if (sequenceStart === null) {
      currFen = [position.moves[moveNum], color, castling, '-', '0', '1'];
    } else {
      currFen = [position.moves[sequenceStart], color, castling, '-', '0', '1'];
    }

    chess.load(currFen.join(' '));

    const newMoves = [];
    let from, to, promotion;
    for (let i = 0; i <= sequenceIndex; i++) {
      [from, to, promotion] = sequence[i].match(/.{1,2}/g);  
      chess.move({ from: from, to: to, promotion: promotion });
      newMoves.push(chess.fen().split(' ')[0]);
    }

    if (window.location.pathname.includes('edit')) {
      this.updateMoves(newMoves);
    } else {
      this.createPosition(newMoves);
    }

    cg.set({ fen: chess.fen() });
    this.setCastling(castling);
    this.drawArrows();
  }

  updateOrientation() {
    cg.toggleOrientation();
    const { orientation } = cg.state;

    this.setState({ orientation });

    if (window.location.pathname.includes('new')) return;

    const { position } = this.state;
    axiosCSRF
      .put(
        '/api/v1/analysis/' + position.id + '.json',
        { position: { orientation: orientation[0] } },
      )
      .catch((error) => {
        console.log(error);
      });
  }

  handleCalculate(color) {
    const { castling, moveNum } = this.state;
    const { dispatch } = this.props;

    this.setState({ error: "", showWait: true });
    const fen = [cg.getFen(), color, castling, '-', '0', '1'].join(' ');

    dispatch(createFen(fen))
      .then((response) => {
        const { analysis } = response;
        this.setState({
          color,
          bestMove: analysis.move.from + analysis.move.to,
          sequence: analysis.sequence,
          sequenceStart: moveNum,
          score: analysis.score,
          showWait: false,
        });
        this.handleMoveAutomatically();
        this.drawArrows();
      })
      .catch((error) => {
        this.setState({ error: error.response.data.errors, showWait: false });
      });
  }

  handleMoveAutomatically() {
    const { currentUser } = this.props;
    if (isEmpty(currentUser) || !currentUser.move_automatically) return;

    this.sequenceMove(0);
  }

  resetBoard() {
    cg.set({ fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR' });
    this.setState({ castling: 'KQkq' });
    this.cgEventChange();
  }

  clearBoard() {
    cg.set({ fen: '8/8/8/8/8/8/8/8' });
    this.setState({ castling: '' });
    this.cgEventChange();
  }

  render() {
    const {
      color,
      bestMove,
      sequence,
      score,
      error,
      moveNum,
      moveCount,
      position,
      orientation,
      showWait,
      castling,
    } = this.state;

    return (
      <div>
        <AnalysisHeader
          position={position}
          color={color}
          bestMove={bestMove}
          sequence={sequence}
          score={score}
          error={error}
          showWait={showWait}
          sequenceMove={this.sequenceMove}
        />
        <div className="row align-items-center">
          <div className="col" />
          <div className="col-lg-6 col-md-12 col-sm-12 mr-3">
            <AnalysisBoard cgEventChange={this.cgEventChange} orientation={orientation} />
          </div>
          <div className="col-lg-4 col-md-9 col-sm-9">
            <AnalysisControls
              onCalculate={this.handleCalculate}
              onMoveNav={this.handleChessgroundMoveNav}
              moveNum={moveNum}
              moveCount={moveCount}
              flipBtn={(
                <button type="button" className="btn btn-outline-info btn-block" onClick={this.updateOrientation}>
                  Flip
                </button>
              )}
              resetBtn={(
                <button type="button" className="btn btn-outline-info btn-block" onClick={this.resetBoard}>
                  Reset
                </button>
              )}
              clearBtn={(
                <button type="button" className="btn btn-outline-warning btn-block" onClick={this.clearBoard}>
                  Clear
                </button>
              )}
              Settings={(
                <AnalysisSettings
                  cgEventChange={this.cgEventChange}
                  position={position}
                />
              )}
            >
              White Castling
              <div className="clearfix" />
              <AnalysisCastlingCheckbox value="K" onToggleCastling={this.updateCastling} castling={castling} label="kingside" />
              <AnalysisCastlingCheckbox value="Q" onToggleCastling={this.updateCastling} castling={castling} label="queenside" />
              <div className="clearfix" />

              Black Castling
              <div className="clearfix" />
              <AnalysisCastlingCheckbox value="k" onToggleCastling={this.updateCastling} castling={castling} label="kingside" />
              <AnalysisCastlingCheckbox value="q" onToggleCastling={this.updateCastling} castling={castling} label="queenside" />
            </AnalysisControls>
          </div>
        </div>
        <br />
        <br />
        <GoogleAdResponsive key={Math.random()} client="ca-pub-6168751190311953" slot="3719458166" format="auto" />
      </div>
    );
  }
}

Analysis.propTypes = {
  dispatch: PropTypes.func,
  chess: PropTypes.object,  
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    move_automatically: PropTypes.bool,
  }),
};

Analysis.defaultProps = {
  dispatch: () => {},
  chess: new Chess(),
  currentUser: {},
};

function mapStateToProps(state) {
  const { currentUser } = state;

  return { currentUser };
}

export default connect(mapStateToProps)(Analysis);
