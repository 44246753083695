import React from 'react';
import PropTypes from 'prop-types';

class AnalysisCastlingCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.handleToggleChange = this.handleToggleChange.bind(this);
  }

  static castlingAvailable(value) {
    switch (value) {
      case 'K':
        return (
          cg.state.pieces.get('e1')
          && cg.state.pieces.get('e1').role === 'king'
          && cg.state.pieces.get('e1').color === 'white'
          && cg.state.pieces.get('h1')
          && cg.state.pieces.get('h1').role === 'rook'
          && cg.state.pieces.get('h1').color === 'white'
        );
      case 'Q':
        return (
          cg.state.pieces.get('e1')
          && cg.state.pieces.get('e1').role === 'king'
          && cg.state.pieces.get('e1').color === 'white'
          && cg.state.pieces.get('a1')
          && cg.state.pieces.get('a1').role === 'rook'
          && cg.state.pieces.get('a1').color === 'white'
        );
      case 'k':
        return (
          cg.state.pieces.get('e8')
          && cg.state.pieces.get('e8').role === 'king'
          && cg.state.pieces.get('e8').color === 'black'
          && cg.state.pieces.get('h8')
          && cg.state.pieces.get('h8').role === 'rook'
          && cg.state.pieces.get('h8').color === 'black'
        );
      case 'q':
        return (
          cg.state.pieces.get('e8')
          && cg.state.pieces.get('e8').role === 'king'
          && cg.state.pieces.get('e8').color === 'black'
          && cg.state.pieces.get('a8')
          && cg.state.pieces.get('a8').role === 'rook'
          && cg.state.pieces.get('a8').color === 'black'
        );
      default:
        return false;
    }
  }


  handleToggleChange(event) {
    const { value, checked } = event.target;
    const { onToggleCastling } = this.props;
    onToggleCastling(value, checked);
  }

  render() {
    const { value, label, castling } = this.props;
    return (
      <div className="form-check form-check-inline">
        <input
          id={`${value}-castling`}
          className="form-check-input"
          type="checkbox"
          value={value}
          checked={castling.includes(value)}
          onChange={this.handleToggleChange}
        />
        {label}
      </div>
    );
  }
}

export default AnalysisCastlingCheckbox;

AnalysisCastlingCheckbox.propTypes = {
  castling: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onToggleCastling: PropTypes.func.isRequired,
};
