import axiosCSRF from '../util/axiosCSRF';

export const RECEIVE_FEN = 'RECEIVE_FEN';

function receiveFen(json) {
  return {
    type: RECEIVE_FEN,
    analysis: json.analysis,
  };
}

export function getFen(fen, rating) {
  return (dispatch) => axiosCSRF
    .get('/api/v1/fens', { params: { fen, rating } })
    .then((response) => dispatch(receiveFen(response.data)));
}

export function createFen(fen) {
  return (dispatch) => axiosCSRF
    .post('/api/v1/fens.json', { fen })
    .then((response) => dispatch(receiveFen(response.data)));
}

export function adminGetFen(fen) {
  return (dispatch) => axiosCSRF
    .get('/admin/api/v1/fens', { params: { fen } })
    .then((response) => dispatch(receiveFen(response.data)));
}
